import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const Faq = () => {

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Frequently Asked Questions
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    We are always happy to answer your Questions. Please reach us at info@techsharingb.com if you cannot find an answer to your question.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* faq section start */}
  <section className="faq-section  pb-md-5 pb-0">
    <div className="container">
      <div className="title-basic">
        <h2 className="text-white">
          Have inquiries? In our FAQ, you'll find all the solutions you need.
        </h2>
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              data-cursor="pointer"
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              What type of Services do we provide?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body">
              <p>
              We mainly provide Technical Services, Technical Content Development services.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              Do we provide our services Globally or only in India?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div className="accordion-body">
              We provide our services Globally if it is remote (considering the nature of our business helps us doing that). We can be available for Global on-site engagements as well if, Visa sponsorship and travel arrangements are taken care of by the customer.
              We are available PAN India and nearby countries with our service delivery where Visa arrangements are not a challenge.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingThree">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              How do we provide remote services for our Global deliveries?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div className="accordion-body">
              We usually connect over Video conferencing software's like - WebEx, G-meet, Zoom , etc.
                For Global customers we have dedicated Customer Engagement Managers who are available for calls as per the agreed time-zone. We have 24*7 support available with prior request from the customers.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingFour">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour"
            >
              How do we accept Payment for our Global service deliveries?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFour"
          >
            <div className="accordion-body">
            We have been accepting overseas payment through Bank wire transfer since our inception and we find it quite smooth and flawless.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingFive">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive"
            >
              How can we trust your Services?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFive"
          >
            <div className="accordion-body">
            We are verified by Dun & Bradstreet. Also, we are registered with most of the Vendors for whom we are doing service deliveries. If you need any references, please feel free to right to us at the email id provided on the top of this page.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* faq section end */}
    </>

  );
};

export default Faq;
