import React, { useState, useEffect } from "react";
import { configData } from "../../config";
import "./my404.scss";


const My404Component = () => {
    return (
        <section className="error-page vh-100" id="error__section_area" style={{ backgroundImage: `url(${configData.BASE_URL + "/images/blur-bg.jpg"})` }}>
            <div className="disconnect_wire" style={{ backgroundImage: `url(${configData.BASE_URL + "/images/404-disconnect.png"})` }}>
            </div>
            <div className="container h-p100">
                <div className="row h-p100">
                    <div className="col-lg-7 col-md-10 col-12">
                        <div className="rounded10 p-50 align_verticle_horizontal_center text-center">
                            {/* <img src={configData.BASE_URL + "/svg/loading.svg"} className="max-w-200" alt="searching" /> */}
                            <h1 className="text-danger fa-5x fw-900 text_shadow">ERROR - 404</h1>
                            <h3 className="text-center text-warning fa-2x fw-900 text_shadow">
                                Page Not Found !!
                            </h3>
                            <h4 className="typing_animation text-warning fw-900 text_shadow">We can't find the page you're looking for.</h4>

                            <div className="sleep-walker">
                                <div className="man">
                                    <div className="head"></div>
                                    <div className="torso">
                                        <div className="arm-a"></div>
                                        <div className="arm-b"></div>
                                    </div>
                                    <div className="legs">
                                        <div className="leg-a"></div>
                                        <div className="leg-b"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-30"><a href="/" className="btn btn-danger text_shadow">Back to Dashboard</a></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    );
};

export default My404Component;
