import React, { useState, useEffect } from "react";
import { configData } from "../../config";
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss'
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';

const VendorRelations = () => {

  return (
    <>
    <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Vendor Relations
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    Your Projects; Built in Our Care
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-section pb-0">
        <div className="container pb-md-5 pb-0">
          <div 
          className="title2 text-center aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-delay={100}>
            <h2 className="text-white pb-3">
            Channel/ Technology Partners
            </h2>
            <div className="row g-xxl-5 g-4">
            <div className="col-xl-1 col-lg-1 col-sm-1"></div>
              <div className="col-xl-10 col-lg-10 col-sm-10">
                  <AwesomeSlider
                  className="w-100"
                  style={{ "--slider-height-percentage": "40%" }}
                    animation="foldOutAnimation">
                        <div 
                          data-src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
                        />
                        <div 
                          data-src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
                        />
                        <div 
                          data-src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
                        />
                        <div 
                          data-src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
                        />
                        <div 
                          data-src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
                        />
                  </AwesomeSlider>
                </div>
                <div className="col-xl-1 col-lg-1 col-sm-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default VendorRelations;
