import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',  // default language
    debug: true,
    interpolation: {
      escapeValue: false,  // React already escapes values
    },
    backend: {
      loadPath: '/lang/{{lng}}/translation.json',
    },
  });

export default i18n;
