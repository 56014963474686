import React, { useState, useEffect } from "react";
import "./home.scss";
import { configData } from "../../config";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState({ "top_header": false, "org_list": false, "stub_list_location": false, "map_area": false });
  const [count, setCount] = useState({});
  const [currentStubList, setCurrentStubList] = useState([]);
  const { t } = useTranslation();

  // const load1 = async () => {
  //   await callApi.getStubList("limit", 10)
  //     .then(response => {
  //       const { data = {} } = response;
  //       setCurrentStubList(data);
  //       return true;
  //     })
  //     .catch(error => {
  //       // console.log("Error in fetching data from JSON" + JSON.stringify(error))
  //       return false
  //     })
  // }


  // useEffect(() => {
  //   load1();
  // }, []);


  return (
    <>
  {/* home section start */}
  <section id="home2" className="section-b-space home-section">
    <div className="container-fluid">
      <div className="home-sec">
        <div className="home-content">
          <div>
            <h2>Test {t('homepage.title')}</h2>
            <h1>{t('description')}</h1>
            <p>
            {t('welcome note')}
            </p>
          </div>
        </div>
        <div className="home-img d-lg-flex d-none">
          <img
            src={`${configData.ASSETS_URL}/assets/images/landing/robot.png`}
            className="img-fluid robot-img"
            alt="robot"
          />
          <div className="round-effect">
            <img
              src={`${configData.ASSETS_URL}/assets/images/landing/round.gif`}
              className="img-fluid "
              alt="robot"
            />
          </div>
          <ul className="home-effect">
            <li>
              <img
                src={`${configData.ASSETS_URL}/assets/images/landing/effect.png`}
                className="img-fluid effect-img"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/service/search.svg`}
                className="img-fluid outline-icon"
                alt=""
              />{" "}
              Development
            </li>
            <li>
              <img
                src={`${configData.ASSETS_URL}/assets/images/landing/effect.png`}
                className="img-fluid effect-img"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/service/graph.svg`}
                className="img-fluid outline-icon"
                alt=""
              />{" "}
              Training
            </li>
            <li>
              <img
                src={`${configData.ASSETS_URL}/assets/images/landing/effect.png`}
                className="img-fluid effect-img"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/service/copy.svg`}
                className="img-fluid outline-icon"
                alt=""
              />{" "}
              Domains
            </li>
            <li>
              <img
                src={`${configData.ASSETS_URL}/assets/images/landing/effect.png`}
                className="img-fluid effect-img"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/service/text.svg`}
                className="img-fluid outline-icon"
                alt=""
              />{" "}
              Remote Labs
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  {/* <section className="home-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="home-content">
            <div className="bg-effect">
              <img
                src={`${configData.ASSETS_URL}/assets/images/home/home-bg.gif`}
                className="img-fluid bg-gif"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/home/1.png`}
                className="img-fluid effect1 rotate-effect"
                alt=""
              />
              <img
                src={`${configData.ASSETS_URL}/assets/svg/home/2.svg`}
                className="img-fluid effect2 rotate-effect"
                alt=""
              />
            </div>
            <div>
              <h1>
              {t('description')}
              <br /><br />
                <div className="title-effect">
                  <img src={`${configData.ASSETS_URL}/assets/images/title-effect.png" alt="" /`}
                  <span>{t('homepage.title')}</span>
                </div>
              </h1>
              <p>
                
              {t('welcome note')}
              </p>
              <a data-cursor="pointer" className="start-link">
                <i className="iconsax" data-icon="play-circle" />
                 Get Quote
              </a>
            </div>
          </div>
          <div className="home-laptop px-md-0 px-3">
            <div className="laptop-sec position-relative">
              <div className="hand-sec">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/home/hand.png`}
                  className="img-fluid left-hand"
                  alt="hand"
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/images/home/hand.png`}
                  className="img-fluid right-hand"
                  alt="hand"
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/images/home/finger.png`}
                  className="img-fluid left-finger"
                  alt="hand"
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/images/home/finger.png`}
                  className="img-fluid right-finger"
                  alt="hand"
                />
              </div>
              <img
                src={`${configData.ASSETS_URL}/assets/images/home/laptop.png`}
                className="img-fluid laptop-img"
                alt="laptop"
              />
            </div>
            <div className="home-info">
              <ul className="info-list">
                <li>Ask anything </li>
                <li>Generate image</li>
                <li>Translate anything</li>
              </ul>
              <ul className="star-rating">
                <li>
                  <i className="ri-star-fill" />
                </li>
                <li>
                  <i className="ri-star-fill" />
                </li>
                <li>
                  <i className="ri-star-fill" />
                </li>
                <li>
                  <i className="ri-star-fill" />
                </li>
                <li>
                  <i className="ri-star-fill" />
                </li>
              </ul>
              <h4>
                A technique to write all of your material 10 times more quickly.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/* home section end */}
  {/* service section start */}
  <div className="service-section section-b-space">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="row g-4 service-row">
            <div className="col-sm-6">
              <div className="service-box">
                <div className="service-icon">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/copy.svg`}
                    className="img-fluid outline-icon"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/copy-bold.svg`}
                    className="img-fluid bold-icon"
                    alt=""
                  />
                </div>
                <div className="service-content">
                  <h3>
                    <img
                      src={`${configData.ASSETS_URL}/assets/svg/service-title.svg`}
                      alt="effect"
                      className="img-fluid"
                    />
                    Technical Services
                  </h3>
                  <p>
                  We can help design, set up and maintain your business's network. As a Managed IT Service Provider we troubleshoot and maintain any new or existing network infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="service-box">
                <div className="service-icon">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/graph.svg`}
                    className="img-fluid outline-icon"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/graph-bold.svg`}
                    className="img-fluid bold-icon"
                    alt=""
                  />
                </div>
                <div className="service-content">
                  <h3>
                    <img
                      src={`${configData.ASSETS_URL}/assets/svg/service-title.svg`}
                      alt="effect"
                      className="img-fluid"
                    />
                    Learning Services
                  </h3>
                  <p>
                  We will work with you to design and develop your Technical presentations and Product Walk-through videos.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="service-box">
                <div className="service-icon">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/search.svg`}
                    className="img-fluid outline-icon"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/search-bold.svg`}
                    className="img-fluid bold-icon"
                    alt=""
                  />
                </div>
                <div className="service-content">
                  <h3>
                    <img
                      src={`${configData.ASSETS_URL}/assets/svg/service-title.svg`}
                      alt="effect"
                      className="img-fluid"
                    />
                    Software Services
                  </h3>
                  <p>
                  We are available to consult with you, if you want to automate your business or planning to launch a new product.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="service-box">
                <div className="service-icon">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/text.svg`}
                    className="img-fluid outline-icon"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/service/text-bold.svg`}
                    className="img-fluid bold-icon"
                    alt=""
                  />
                </div>
                <div className="service-content">
                  <h3>
                    <img
                      src={`${configData.ASSETS_URL}/assets/svg/service-title.svg`}
                      alt="effect"
                      className="img-fluid"
                    />
                    TLS - Upskill and Job Initiative
                  </h3>
                  <p>
                  We call it as TLS:
                    T - Target     
                    L - Learn     
                    S - Succeed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="service-info">
            <div>
              <div className="title">
                <h2>We (Techsharingb) are</h2>
                <h3>
                a multi-functional team with expertise in various areas. It has been predominantly working in two major areas –Technical services and Learning services
                </h3>
              </div>
              <p>
              Techsharingb is a talented bunch of geeks who are highly motivated to deliver the best Technical Solutions, and Learning and Development services to satisfy the business needs of its customers. It is young and growing on a daily basis. Quality Assurance, Timely Delivery and Customer Satisfaction are in its DNA. The hunger to explore new technology and update accordingly makes it relevant in the market.
              </p>
              {/* <a data-cursor="pointer" className="btn-arrow" href="/service">
                <div className="icon-arrow">
                  <i className="iconsax" data-icon="arrow-up" />
                </div>
                View all
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* service section end */}
  {/* about section start */}
  <section className="about-section section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-left"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6 order-lg-0 order-1">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">01.</span>
                <h2 className="text-white">
                Technical Services
                </h2>
              </div>
              <p>
              We help businesses to find out proper Technical Solutions which can satisfy their specific
              requirements in the field of Networking and Automation as well as Cyber Security. We help our customers with Professional Services for their Infrastructure Design and Implementation. 
              With the help of our Vendor relations we help our customers to go for a PoC/ PoV even before finalizing a solution and going ahead with any infrastructure investment. 
              Under Managed Services we take care of the NOC (Network Operations Center)/ SOC (Security Operations Center) of Enterprises.
              </p>
              <ul>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Product and Solutions Expertise
                </li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Vendor Expertise
                </li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Services
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/1.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about section end */}
  {/* about section start */}
  <section className="about-section right-version section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-right"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/2.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">02.</span>
                <h2>Content Development</h2>
              </div>
              <p>
                We help our Partners and Customers with Technically rich Slide Decks for new Product launch as well as for Channel Presentations or Webinars/ Seminars.
              </p>
              
              <ul>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Technical Presentations
                </li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Product Walk-through Videos
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about section end */}
  {/* about section start */}
  <section className="about-section section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-left"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6 order-lg-0 order-1">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">03.</span>
                <h2 className="text-white">
                Software Services
                </h2>
              </div>
              <p>We have got the expertise to develop rich videos demonstrating the specific feature sets of the Products.</p>
              <ul>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Technical Presentations
                </li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Product Walk-through Videos
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/3.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about section end */}
    {/* about section start */}
    <section className="about-section right-version section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-right"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/2.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">04.</span>
                <h2>TLS - Upskill and Job Initiative</h2>
              </div>
              <p>
              Nice e-meeting you! We are here to guide you identify your career path at any stage of your life and help you with the necessary tools to walk that path.
              </p>
              
              <ul>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Career Guide
                </li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                  Upskill Freeware
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about section end */}
  {/* feature section start */}
  <section className="feature-section section-b-space">
    <div className="bg-effect">
      <img
        src={`${configData.ASSETS_URL}/assets/images/feature.gif`}
        className="img-fluid feature-left"
        alt=""
      />
      <img
        src={`${configData.ASSETS_URL}/assets/images/feature.gif`}
        className="img-fluid feature-right"
        alt=""
      />
      <img
        src={`${configData.ASSETS_URL}/assets/images/feature-bg.png`}
        className="img-fluid feature-bg"
        alt=""
      />
      <span className="round-effect" />
    </div>
    <div className="container">
      <div className="title-basic">
        <h2>
          Unleash limitless potential &amp; versatile features for every need
        </h2>
      </div>
      <div className="swiper featureSlider">
        <div id="featureSlider" className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="row g-xxl-5 g-4">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ask anything</h3>
                  </div>
                  <h4>
                    Unlock the power of curiosity, Ask anything and let our AI
                    unravel the answers for you!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Code generator</h3>
                  </div>
                  <h4>
                    Discover the power of technology, making your coding dreams
                    a reality.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>ASO expert</h3>
                  </div>
                  <h4>
                    Boost your app's success and take over the app stores and
                    win the attention of millions.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Insta caption</h3>
                  </div>
                  <h4>
                    One caption at a time, unveiling the endless potential of AI
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Email generator</h3>
                  </div>
                  <h4>
                    Generating emails that captivates and expresses with
                    brilliance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Hashtag writer</h3>
                  </div>
                  <h4>
                    Boost your content journey by utilising the power of
                    hashtags!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>E-commerce</h3>
                  </div>
                  <h4>
                    Promoting sales, enhancing customer experience, and
                    redefining product recommendations.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ads &amp; marketing</h3>
                  </div>
                  <h4>
                    Tap into data-driven facts, target the right clientele, and
                    maximize ad performance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="row g-xxl-5 g-4">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ask anything</h3>
                  </div>
                  <h4>
                    Unlock the power of curiosity, Ask anything and let our AI
                    unravel the answers for you!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Code generator</h3>
                  </div>
                  <h4>
                    Discover the power of technology, making your coding dreams
                    a reality.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>ASO expert</h3>
                  </div>
                  <h4>
                    Boost your app's success and take over the app stores and
                    win the attention of millions.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Insta caption</h3>
                  </div>
                  <h4>
                    One caption at a time, unveiling the endless potential of AI
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Email generator</h3>
                  </div>
                  <h4>
                    Generating emails that captivates and expresses with
                    brilliance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Hashtag writer</h3>
                  </div>
                  <h4>
                    Boost your content journey by utilising the power of
                    hashtags!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>E-commerce</h3>
                  </div>
                  <h4>
                    Promoting sales, enhancing customer experience, and
                    redefining product recommendations.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ads &amp; marketing</h3>
                  </div>
                  <h4>
                    Tap into data-driven facts, target the right clientele, and
                    maximize ad performance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="row g-xxl-5 g-4">
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/message-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ask anything</h3>
                  </div>
                  <h4>
                    Unlock the power of curiosity, Ask anything and let our AI
                    unravel the answers for you!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/code-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Code generator</h3>
                  </div>
                  <h4>
                    Discover the power of technology, making your coding dreams
                    a reality.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/play-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>ASO expert</h3>
                  </div>
                  <h4>
                    Boost your app's success and take over the app stores and
                    win the attention of millions.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/insta-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Insta caption</h3>
                  </div>
                  <h4>
                    One caption at a time, unveiling the endless potential of AI
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/mail-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Email generator</h3>
                  </div>
                  <h4>
                    Generating emails that captivates and expresses with
                    brilliance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Hashtag writer</h3>
                  </div>
                  <h4>
                    Boost your content journey by utilising the power of
                    hashtags!
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/box-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>E-commerce</h3>
                  </div>
                  <h4>
                    Promoting sales, enhancing customer experience, and
                    redefining product recommendations.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="feature-box">
                  <div className="feature-top">
                    <div className="feature-icon">
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing.svg`}
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src={`${configData.ASSETS_URL}/assets/svg/feature/marketing-bold.svg`}
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <h3>Ads &amp; marketing</h3>
                  </div>
                  <h4>
                    Tap into data-driven facts, target the right clientele, and
                    maximize ad performance.
                  </h4>
                  <div data-cursor="pointer" className="link-overflow">
                    <a href="/service">
                      Read more{" "}
                      <i className="iconsax" data-icon="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pagination-effect">
      <img
        src={`${configData.ASSETS_URL}/assets/svg/pagination-robot.svg`}
        className="img-fluid"
        alt=""
      />
    </div>
    <div className="swiper-pagination feature-pagination" />
  </section>
  {/* feature section end */}

  {/* testimonial section start */}
  <section className="testimonial-section section-b-space">
    <div className="container">
      <div className="title-basic">
        <h2>Check out how much TechSharingB is loved by our users!</h2>
      </div>
      <div className="swiper testimonialSlider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="testimonial-box">
              <div className="content-sec">
                <div className="quote-img">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote.svg`}
                    className="img-fluid outline-img"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote-green.svg`}
                    className="img-fluid fill-img"
                    alt=""
                  />
                </div>
                <p>
                  {" "}
                  The quality of the generated text is impressive, and it saves
                  me so much time and effort. Highly recommended!
                </p>
              </div>
              <div className="small-circle">
                <span />
              </div>
              <div className="large-circle">
                <span />
              </div>
              <div className="avtar-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/user/1.jpg`}
                  className="img-fluid"
                  alt="user"
                />
              </div>
              <h4>Marvin McKinney</h4>
              <h5>Content writer</h5>
            </div>
          </div>
          <div
            className="swiper-slide"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={400}
          >
            <div className="testimonial-box">
              <div className="content-sec">
                <div className="quote-img">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote.svg`}
                    className="img-fluid outline-img"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote-green.svg`}
                    className="img-fluid fill-img"
                    alt=""
                  />
                </div>
                <p>
                  As a marketer, I'm always looking for ways to streamline my
                  work. The AI Tool has been a fantastic addition to my toolkit.
                  It helps me come up with creative ideas!
                </p>
              </div>
              <div className="small-circle">
                <span />
              </div>
              <div className="large-circle">
                <span />
              </div>
              <div className="avtar-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/user/2.jpg`}
                  className="img-fluid"
                  alt="user"
                />
              </div>
              <h4>Marvin McKinney</h4>
              <h5>Content writer</h5>
            </div>
          </div>
          <div
            className="swiper-slide"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={500}
          >
            <div className="testimonial-box">
              <div className="content-sec">
                <div className="quote-img">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote.svg`}
                    className="img-fluid outline-img"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote-green.svg`}
                    className="img-fluid fill-img"
                    alt=""
                  />
                </div>
                <p>
                  I was skeptical about using AI for content generation, but
                  after trying out the AI Generation Tool, I was pleasantly
                  surprised.
                </p>
              </div>
              <div className="small-circle">
                <span />
              </div>
              <div className="large-circle">
                <span />
              </div>
              <div className="avtar-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/user/3.jpg`}
                  className="img-fluid"
                  alt="user"
                />
              </div>
              <h4>Marvin McKinney</h4>
              <h5>Content writer</h5>
            </div>
          </div>
          <div
            className="swiper-slide"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={200}
          >
            <div className="testimonial-box">
              <div className="content-sec">
                <div className="quote-img">
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote.svg`}
                    className="img-fluid outline-img"
                    alt=""
                  />
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/quote-green.svg`}
                    className="img-fluid fill-img"
                    alt=""
                  />
                </div>
                <p>
                  The AI Generation Tool has revolutionized the way I write.
                  Whether I need help with brainstorming ideas or fleshing out
                  drafts.
                </p>
              </div>
              <div className="small-circle">
                <span />
              </div>
              <div className="large-circle">
                <span />
              </div>
              <div className="avtar-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/user/3.jpg`}
                  className="img-fluid"
                  alt="user"
                />
              </div>
              <h4>Marvin McKinney</h4>
              <h5>Content writer</h5>
            </div>
          </div>
        </div>
        <div className="swiper-pagination" />
      </div>
    </div>
  </section>
  {/* testimonial section end */} 
  {/* faq section start */}
  <section className="faq-section">
    <div className="container">
      <div className="title-basic">
        <h2 className="text-white">
          Have inquiries? In our FAQ, you'll find all the solutions you need.
        </h2>
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              data-cursor="pointer"
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              What type of Services do we provide?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body">
              <p>
              We mainly provide Technical Services, Technical Content Development services.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              Do we provide our services Globally or only in India?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div className="accordion-body">
              We provide our services Globally if it is remote (considering the nature of our business helps us doing that). We can be available for Global on-site engagements as well if, Visa sponsorship and travel arrangements are taken care of by the customer.
              We are available PAN India and nearby countries with our service delivery where Visa arrangements are not a challenge.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingThree">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              How do we provide remote services for our Global deliveries?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div className="accordion-body">
              We usually connect over Video conferencing software's like - WebEx, G-meet, Zoom , etc.
                For Global customers we have dedicated Customer Engagement Managers who are available for calls as per the agreed time-zone. We have 24*7 support available with prior request from the customers.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingFour">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour"
            >
              How do we accept Payment for our Global service deliveries?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFour"
          >
            <div className="accordion-body">
            We have been accepting overseas payment through Bank wire transfer since our inception and we find it quite smooth and flawless.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingFive">
            <button
              data-cursor="pointer"
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive"
            >
              How can we trust your Services?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFive"
          >
            <div className="accordion-body">
            We are verified by Dun & Bradstreet. Also, we are registered with most of the Vendors for whom we are doing service deliveries. If you need any references, please feel free to right to us at the email id provided on the top of this page.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* faq section end */}
</>

  );
};

export default Home;
