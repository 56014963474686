import React, { useEffect, useState, memo } from 'react';
import { configData } from '../config.js';
import './header.scss';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const [clicked_menu, setClickedMenu] = useState("dashboard");
	const [active_sub_menu, setSubMenu] = useState("dashboard");
	const [sessionActive, setsessionActive] = useState(null);
	const { i18n } = useTranslation();

	const setClickedMenuData = (menu_type) => {
		if(clicked_menu === menu_type){
			setClickedMenu("");
		}else{			
			setClickedMenu(menu_type);
		}
	}

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };



	return (
		<>
		<div className="navbar fixed-navbar">
  <div className="left">
    
  </div>
  <div className="right">
  <div 
				className="d-sm-inline-block d-none">
					<select className='cstm_btn' onChange={(e) => changeLanguage(e)}>
						<option value="en">English</option>
						<option value="fr">Français</option>
					</select>
			</div>
  </div>
</div>
		<header>
			<button
				className="navbar-toggler d-xl-none d-inline navbar-menu-button"
				type="button"
				data-bs-toggle="offcanvas"
				data-bs-target="#primaryMenu"
			>
				<span className="navbar-toggler-icon">
				<i class="fa fa-bars" aria-hidden="true"></i>
				</span>
			</button>
			<a href="/">
				<img src={`${configData.ASSETS_URL}/assets/images/tsb-logo.png`} className="img-fluid" alt="logo" />
			</a>
			<nav className="header-nav-right">
				<div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
				<div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
					<div className="offcanvas-header navbar-shadow">
					<h5 className="mb-0">Back</h5>
					<button
						className="btn-close lead"
						type="button"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					/>
					</div>
					<div className="offcanvas-body">
					<ul className="navbar-nav">
						<li className="nav-item">
							<a className="nav-link" href="/portfolio">
								Portfolio
							</a>
						</li>
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown-toggle"
								href="#" onClick={(e) => e.preventDefault()}
								data-bs-toggle="dropdown">
								Learning
							</a>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="/learning/training">
										Training
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/learning/content-development">
										Content Development
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/learning/remote-labs">
										Remote Labs
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/learning/job-oriented">
										Job Oriented
									</a>
								</li>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown-toggle"
								href="#" onClick={(e) => e.preventDefault()}
								data-bs-toggle="dropdown">
								Technology
							</a>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="/technology/technical-services">
										Technical Services
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/technology/vendor-relations">
										Vendor Reations
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/technology/technical-domains">
										Domains
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/technology/design-and-delivery-models">
										Design & Delivery Models
									</a>
								</li>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown-toggle"
								href="#" onClick={(e) => e.preventDefault()}
								data-bs-toggle="dropdown">
								Software
							</a>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="/software/design-and-development">
										Design & Development
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/software/technologies">
										Technologies
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/software/industries">
										Industries
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/software/products">
										Products
									</a>
								</li>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown-toggle"
								href="#" onClick={(e) => e.preventDefault()}
								data-bs-toggle="dropdown">
								Pages
							</a>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="/career">
										Carrer
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/about-us">
										About Us
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/news-and-events">
										News & Events
									</a>
								</li>
								<li>
									<a className="dropdown-item" href="/faq">
										FAQ's
									</a>
								</li>
							</ul>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/blogs">
								Blog
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/contact-us">
								Reach Us
							</a>
						</li>
					</ul>
					</div>
				</div>
				</div>
			</nav>
			{/* <a
				data-cursor="pointer"
				href="/login"
				className="btn btn-theme d-sm-inline-block d-none"
			>
				<span>Login Now</span>
			</a> */}
			{/* <div 
				className="d-sm-inline-block d-none">
					<select className='cstm_btn' onChange={(e) => changeLanguage(e)}>
						<option value="en">English</option>
						<option value="fr">Français</option>
					</select>
			</div> */}
		</header>
</>
	)
}


// export default connect(mapStateToProps, mapDispatchToProps)(Header);
export default memo(Header);