import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const Contact = () => {

  return (
    <>
  <section className="breadcrumb-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="breadcrumb-content">
            <div>
              <h2>
                <img
                  src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                  className="img-fluid"
                  alt="title-effect"
                />
                Contact us
              </h2>
              <p>
                <i className="ri-subtract-line" />
                Email us with any questions, we would be happy to answer your
                question.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-inline-block d-none">
          <div className="breadcrumb-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
              className="img-fluid"
              alt="service"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="contact-section pb-md-5 pb-0">
    <div className="container">
      <ul className="contact-box">
        <li>
          <div className="contact-icon">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact/message.svg`}
              className="img-fluid"
              alt="message"
            />
          </div>
          <h3>Mail</h3>
          <h4>info@techsharingb.com</h4>
          <h4>inquiry@techsharingb.com</h4>
        </li>
        {/* <li>
          <div className="contact-icon">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact/contact.svg`}
              className="img-fluid"
              alt="message"
            />
          </div>
          <h3>Contact no.</h3>
          <h4>(+91) 9102999313</h4>
        </li> */}
        <li>
          <div className="contact-icon">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact/route.svg`}
              className="img-fluid"
              alt="message"
            />
          </div>
          <h3>Corporate Head Office</h3>
          <h4>320, Ground Floor, Service Rd, NGR Layout, Bommanahalli</h4>
          <h4>Bengaluru, Karnataka 560068. +9102999313.</h4>
        </li>
        <li>
          <div className="contact-icon">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact/address.svg`}
              className="img-fluid"
              alt="message"
            />
          </div>
          <h3>Corporate Regional Office</h3>
          <h4>Techsharingb LLC, Hungary, Europe</h4>
          <h4>Ady endre 1/A, Nagyhegyes, 4064, Hungary. +36202240271.</h4>
        </li>
      </ul>
      <div className="contact-details">
        <div className="row g-lg-5 g-4">
          <div className="col-xl-7 col-lg-6">
            <form className="auth-form">
              <div className="row g-4">
                <div className="col-sm-6">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input type="email" className="form-control" placeholder="Enter Your Full Name" name="name" id="name" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="email" className="form-label">
                    Email *
                  </label>
                  <input type="email" className="form-control" placeholder="Enter Your Email" name="email" id="email" />
                </div>
                <div className="col-12">
                  <label htmlFor="contactNumber" className="form-label">
                      Contact number
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Contact Number"
                      className="form-control"
                      name="contactNumber"
                      id="contactNumber"
                    />
                </div>
                <div className="col-12">
                  <label htmlFor="message" className="form-label">
                    Additional Message
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter your Message"
                    name="message"
                    id="message"
                    rows={3}
                    defaultValue={""}
                  />
                </div>
                <div className="col-12">
                  <a href="#" data-cursor="pointer" className="btn-solid">
                    Send message
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="map-sec">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.9840912326104!2d77.62413267454562!3d12.908743916254338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0be255d39e69%3A0x7b819bed4c7fe6ac!2sTechsharingb%20Private%20Limited!5e0!3m2!1sen!2sin!4v1729784321954!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


  );
};

export default Contact;
