import React, { memo } from 'react';
import { configData } from '../config.js';
import '../css/footer.scss';

const Appfooter = () => {
    return (
        <>
  {/* footer section start*/}
  <section className="info-section section-b-space">
    <div className="container">
      <div
        className="info-box"
        data-aos="fade-in"
        data-aos-duration={1000}
        data-aos-delay={100}
      >
        <div className="hand-effect d-md-block d-none">
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid left-hand"
            alt="hand"
          />
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid right-hand"
            alt="hand"
          />
        </div>
        <h2>
          Ready to{" "}
          <span>
            move{" "}
            <img
              src={`${configData.ASSETS_URL}/assets/svg/title-effect.svg`}
              className="img-fluid"
              alt="title-effect"
            />
          </span>
          ahead?
        </h2>
        <p>
          With the help of our ground-breaking AI tool, unlock the potential of
          cutting-edge AI technology and increase your productivity to new
          heights. Embrace the future today and let our AI tool redefine what's
          possible for you.
        </p>
        <ul>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Free images for lifetime
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Get details on any topic
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Quick advisor to help you
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            15+ category to explore
          </li>
        </ul>
        <a data-cursor="pointer" className="btn-arrow" href="#">
          <div className="icon-arrow">
            <i className="iconsax" data-icon="arrow-up" />
          </div>
          Contact us now
        </a>
      </div>
    </div>
  </section>
  <footer>
    <div className="container">
      <div className="footer-row">
        <div className="footer-main">
          <a href="#" className="footer-logo">
            <img 
            src={`${configData.ASSETS_URL}/assets/images/tsb-logo.png"`}
            className="img-fluid" alt="" />
          </a>
          <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
          <form>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your mail"
              />
              <a href="#" data-cursor="pointer" className="btn-basic">
                Subscribe
              </a>
            </div>
          </form>
          <ul className="social-links">
            <li>
              <a data-cursor="pointer" href="https://www.facebook.com/techsharingbpvtltd">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/social/fb.svg`}
                  className="img-fluid"
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a data-cursor="pointer" href="https://www.linkedin.com/company/techsharingb-pvt-ltd/">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/social/linkedin.svg`}
                  className="img-fluid"
                  alt="linkedin"
                />
              </a>
            </li>
            <li>
              <a data-cursor="pointer" href="https://www.instagram.com/techsharingb">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/social/insta.svg`}
                  className="img-fluid"
                  alt="insta"
                />
              </a>
            </li>
            <li>
              <a data-cursor="pointer" href="https://www.twitter.com/techsharingb">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/social/twitter.svg`}
                  className="img-fluid"
                  alt="twitter"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="link-section">
          <div className="footer-title">

          </div>
        </div>
        <div className="link-section">
          <div className="footer-title">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/star.svg`}
              className="img-fluid"
              alt="star"
            />
            Quick Link
          </div>
          <div className="footer-content">
            <ul>
              <li>
                <a data-cursor="pointer" href="/portfolio">
                  Company
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/career">
                  Career
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/blogs">
                  Blogs
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="link-section">
          <div className="footer-title">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/star.svg`}
              className="img-fluid"
              alt="star"
            />
            Our service
          </div>
          <div className="footer-content">
            <ul>
              <li>
                <a data-cursor="pointer" href="/">
                  Service 1
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/">
                Service 2
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/">
                Service 3
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/">
                Service 4
                </a>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="link-section">
          <div className="footer-title">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/star.svg`}
              className="img-fluid"
              alt="star"
            />
            Help
          </div>
          <div className="footer-content">
            <ul>
              <li>
                <a data-cursor="pointer" href="/faq">
                  FAQs
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/contact-us">
                  Reach Us
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/report-bug">
                  Report a Bug
                </a>
              </li>
              <li>
                <a data-cursor="pointer" href="/sitemap">
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <h4>Copyright © 2024 Techsharingb - All Rights Reserved.</h4>
        <ul className="footer-links">
          <li>
            <a href="/privacy-policy">Privacy Policy </a>
          </li>
          <li>
            <a href="/term-and-condition">Terms &amp; Condition </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</>

    )
}
export default memo(Appfooter);