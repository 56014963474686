

const BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : 'http://test.techsharingb.in';
const ASSETS_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : 'http://test.techsharingb.in';
const READ_API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : 'http://test.techsharingb.in';

export const configData = {
    BASE_URL,
    ASSETS_URL,
    READ_API_URL,
    // READ_API_MANAGER_URL,
    "DUMMY_IMG": "images/stub.svg",
    "THEME_COLORS": {
        "PRIMARY": "#A600E0",
        "SECONDARY": "#c1287b"
    },
    "SECRET_KEY": "u7x!A%0*G-KaTdRgF$Xp2s56v8y/B?EH",
};
