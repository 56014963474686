import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const RemoteLabs = () => {

  return (
    <>
    <section className="breadcrumb-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="breadcrumb-content">
          <div>
            <h2>
              <img
                src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                className="img-fluid"
                alt="title-effect"
              />
              Remote Labs
            </h2>
            <p>
              <i className="ri-subtract-line" />
              Discover the most recent blogs about artificial intelligence here.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 d-lg-inline-block d-none">
        <div className="breadcrumb-img">
          <img
            src={`${configData.ASSETS_URL}/assets/svg/blog-details-vector.svg`}
            className="img-fluid"
            alt="service"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="ratio_40">
  <div className="container">
    <div className="blog-details">
      <div className="blog-img">
        <img
          src={`${configData.ASSETS_URL}/assets/images/blog/7.jpg`}
          className="img-fluid w-100 bg-img"
          alt="blog"
        />
      </div>
      <div className="blog-title-box">
        <a
          data-cursor="pointer"
          className="main-title"
          href="blog-details.html"
        >
          Proof of Concept/ Dedicated Setup
        </a>
        <ul>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/images/user/2.jpg`}
              className="img-fluid avtar"
              alt="author"
            />{" "}
            Smith John
          </li>
          <li>
            <i className="iconsax" data-icon="calendar-add" /> 14 march 2023
          </li>
          <li>
            <i className="iconsax" data-icon="clock" /> 5 min
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-10 m-auto">
          <div className="blog-main-content">
            <h3>
              <img
                src={`${configData.ASSETS_URL}/assets/svg/title-effect2.svg`}
                className="img-fluid"
                alt="effect"
              />
              OVERVIEW
            </h3>
            <p>
            Do you want to test drive a newly launched product for your business use-cases? We have got you covered. We can provide you the necessary infrastructure and help design your dedicated setup.
            </p>
            <p>
            Do you want to test drive a newly launched product for your business use-cases? We have got you covered. We can provide you the necessary infrastructure and help design your dedicated setup.
            </p>
            <a
              data-cursor="pointer"
              className="btn-arrow"
              href="/"
            >
              <div className="icon-arrow">
                <i className="iconsax" data-icon="arrow-up" />
              </div>
              Want to Discuss?
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </>

  );
};

export default RemoteLabs;
