import React, { useState, useEffect } from "react";
import { configData } from "../../config";
const ContentDevelopment = () => {

  return (
    <>
     <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Content Development
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    You Demand; We Deliver
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section id="features" className="core-feature section-b-space">
  <div className="container">
    <div
      className="title2 text-center aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration={1000}
      data-aos-delay={100}
    >
      <h2>Technical Presentations</h2>
      <h3>We help our Partners and Customers with Technically rich Slide Decks for new Product launch as well as for Channel Presentations or Webinars/ Seminars.</h3>

      </div>
      <div className="row g-5 mt-5">
        <div className="col-lg-6">
          <div className="service-info">
            <div>
              <div className="title">
                <h2>Product Walk-through Videos</h2>
                <h3>
                Contact Us for Schedule and Pricing
                </h3>
              </div>
              <p>
                We have got the expertise to develop rich videos demonstrating the specific feature sets of the Products. We have got the expertise to develop rich videos demonstrating the specific feature sets of the Products. We have got the expertise to develop rich videos demonstrating the specific feature sets of the Products. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/images/content.webp`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
  </div>
</section>
    </>

  );
};

export default ContentDevelopment;
