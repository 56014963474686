import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const ReportBug = () => { 

  return (
    <>
    <section className="breadcrumb-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="breadcrumb-content">
              <div>
                <h2>
                  <img
                    src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                    className="img-fluid"
                    alt="title-effect"
                  />
                  Report A Bug
                </h2>
                <p>
                  <i className="ri-subtract-line" />
                  Please drop us below, the details about the Bug!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-lg-inline-block d-none">
            <div className="breadcrumb-img">
              <img
                src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                className="img-fluid"
                alt="service"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="contact-section pb-md-5 pb-0">
      <div className="container">
        <div className="contact-details">
          <div className="row g-lg-5 g-4">
            <div className="col-xl-1 col-lg-1"></div>
            <div className="col-xl-10 col-lg-10">
              <form className="auth-form">
                <div className="row g-4">
                  <div className="col-sm-6">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <input type="email" className="form-control" placeholder="Enter Your Full Name" name="name" id="name" />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="form-label">
                      Email *
                    </label>
                    <input type="email" className="form-control" placeholder="Enter Your Email" name="email" id="email" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="contactNumber" className="form-label">
                      Contact number
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Contact Number"
                      className="form-control"
                      name="contactNumber"
                      id="contactNumber"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="message" className="form-label">
                      Message *
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="How did you find the bug?"
                      id="message"
                      rows={3}
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="message" className="form-label">
                      Attachments
                    </label>
                    <input type="file" className="form-control" name="upload_file" id="upload_file" />
                  </div>
                  <div className="col-12">
                    <a href="#" data-cursor="pointer" className="btn-solid">
                      Send
                    </a>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-1 col-lg-1"></div>
          </div>
        </div>
      </div>
    </section>
    </>

  );
};

export default ReportBug;
