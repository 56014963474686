import { OrganisationList } from './ActionType';

let initialState = {};

const stubReducer = (state = initialState, action) => {
    const {type, payload = {}} = action;
    let newState = {};

    switch (type) {
        case OrganisationList.organisationCode :  
            newState = { ...state, ...payload }
            break;
        // case "leftMenu":  
        //     newState = { ...state, ...payload }
        //     break;
        default : newState = state;
    }

    return newState;
};

export default stubReducer;