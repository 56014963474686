import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const Services = () => {

  return (
    <>
  {/* breadcrumb section start */}
  <section className="breadcrumb-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="breadcrumb-content">
            <div>
              <h2>
                <img
                  src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                  className="img-fluid"
                  alt="title-effect"
                />
                Services
              </h2>
              <p>
                <i className="ri-subtract-line" />
                We offer AI consulting services and solutions that will help you
                achieve your business objectives faster, while setting you up
                for sustainable growth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-inline-block d-none">
          <div className="breadcrumb-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/service-vector.svg`}
              className="img-fluid"
              alt="service"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* breadcrumb section end */}
  {/* service section start */}
  <section className="feature-section pb-0">
    <div className="container pb-md-5 pb-0">
      <div className="title-basic fs-sm">
        <h2 className="text-white">
          A Wide Range of Functionality to Support Any Use Case
        </h2>
      </div>
      <ul className="tab-section">
        <li className="active">
          <a href="#">Quick service</a>
        </li>
        <li>
          <a href="#">Social media</a>
        </li>
        <li>
          <a href="#">Email generator</a>
        </li>
        <li>
          <a href="#">Personal advisor</a>
        </li>
        <li>
          <a href="#">Ecommerce</a>
        </li>
        <li>
          <a href="#">Ads &amp; marketing</a>
        </li>
        <li>
          <a href="#">ASO expert</a>
        </li>
      </ul>
      <div className="row g-xxl-5 g-4">
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/message.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/message-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Ask anything</h3>
            </div>
            <h4>
              Unlock the power of curiosity, Ask anything and let our AI unravel
              the answers for you!
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/code.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/code-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Code generator</h3>
            </div>
            <h4>
              Discover the power of technology, making your coding dreams a
              reality.
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/play.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/play-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>ASO expert</h3>
            </div>
            <h4>
              Boost your app's success and take over the app stores and win the
              attention of millions.
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/insta.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/insta-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Insta caption</h3>
            </div>
            <h4>
              One caption at a time, unveiling the endless potential of AI
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/mail.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/mail-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Email generator</h3>
            </div>
            <h4>
              Generating emails that captivates and expresses with brilliance.
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/hashtag-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Hashtag writer</h3>
            </div>
            <h4>
              Boost your content journey by utilising the power of hashtags!
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/box.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/box-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>E-commerce</h3>
            </div>
            <h4>
              Promoting sales, enhancing customer experience, and redefining
              product recommendations.
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6">
          <div className="feature-box">
            <div className="feature-top">
              <div className="feature-icon">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/marketing.svg`}
                  className="img-fluid outline-icon"
                  alt=""
                />
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/feature/marketing-bold.svg`}
                  className="img-fluid bold-icon"
                  alt=""
                />
              </div>
              <h3>Ads &amp; marketing</h3>
            </div>
            <h4>
              Tap into data-driven facts, target the right clientele, and
              maximize ad performance.
            </h4>
            <div data-cursor="pointer" className="link-overflow">
              <a>
                Read more <i className="iconsax" data-icon="arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* service section end */}
  {/* contact us start */}
  <section className="info-section section-b-space">
    <div className="container">
      <div
        className="info-box"
        data-aos="fade-in"
        data-aos-duration={1000}
        data-aos-delay={100}
      >
        <div className="hand-effect d-md-block d-none">
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid left-hand"
            alt="hand"
          />
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid right-hand"
            alt="hand"
          />
        </div>
        <h2>
          Ready to{" "}
          <span>
            move{" "}
            <img
              src={`${configData.ASSETS_URL}/assets/svg/title-effect.svg`}
              className="img-fluid"
              alt="title-effect"
            />
          </span>
          ahead?
        </h2>
        <p>
          With the help of our ground-breaking AI tool, unlock the potential of
          cutting-edge AI technology and increase your productivity to new
          heights. Embrace the future today and let our AI tool redefine what's
          possible for you.
        </p>
        <ul>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Free images for lifetime
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Get details on any topic
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Quick advisor to help you
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            15+ category to explore
          </li>
        </ul>
        <a data-cursor="pointer" className="btn-arrow" href="#">
          <div className="icon-arrow">
            <i className="iconsax" data-icon="arrow-up" />
          </div>
          Contact us now
        </a>
      </div>
    </div>
  </section>
  {/* contact us end */}
</>

  );
};

export default Services;
