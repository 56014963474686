import React, { useState, useEffect } from "react";
import { configData } from "../../config";
import "./css/about.scss";



const About = () => {

  return (
    <div id="about-us-area">
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    About Us
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    Lorem ipsum content{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section id="features" className="core-feature section-b-space">
  <div className="container">
    <div
      className="title2 text-center aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration={1000}
      data-aos-delay={100}
    >
      <h2>We (Techsharingb) are</h2>
      <h3>a multi-functional team with expertise in various areas. It has been predominantly working in two major areas –Technical services and Learning services</h3>

      </div>
      <div className="row g-5 mt-5">
        <div className="col-lg-6">
          <div className="service-info">
            <div>
              <div className="title">
                <h2>Our Team</h2>
                <h3>
                Techsharingb is a talented bunch of geeks
                </h3>
              </div>
              <p>
              Techsharingb is a talented bunch of geeks who are highly motivated to deliver the best Technical Solutions, and Learning and Development services to satisfy the business needs of its customers. It is young and growing on a daily basis. Quality Assurance, Timely Delivery and Customer Satisfaction are in its DNA. The hunger to explore new technology and update accordingly makes it relevant in the market.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/1.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
  </div>
</section>

    </div>
  );
};

export default About;
