import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const Career = () => {

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Career
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    Are you a Problem Solver?
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  <section className="about-section right-version section-b-space section-mb-space pb-md-5 pb-0 mt-5">
    <div
      className="bg-effect"
      data-aos="fade-left"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4 pb-5">
        <div className="col-lg-6 order-lg-0 order-1">
          <div className="about-content">
            <div>
              <div className="title">
                <h2 className="text-white">
                We're Hiring!
                </h2>
              </div>
              <p>
              If you think that you are Problem Solver  and are ready to Learn on a daily basis, then, you are at the right place. We are constantly looking out for young and dynamic individuals for the following Job roles.
              </p>
            <ul className="pb-4">
              <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Technical Trainer</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Technical Content Developer</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Network and Security Engineer</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Solution Architect</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Digital Marketing Executive</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Business Development Manager</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Technical Sales and Key Accounts Manager</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Technical Program Manager (Learning Services)</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Technical Project Manager (Professional Services)</li>
                <li>
                  <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Human Resources Administrator</li>
              </ul>
            <p >
              If you think that your skills are not listed above, still you can apply with your resume. Just elaborate on the reason of why do you want to work with us?
              </p>
              <p>
              *If you want to know more about any of the above Job roles then, please feel free to right to us at career@techsharingb.com
              </p>
              
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/1.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
            
        </div>
      </div>
      <div className="row g-md-5 g-4 pb-5">
      <div className="col-xl-1 col-lg-1"></div>
      <div className="col-xl-10 col-lg-10">
        <div className="title">
          
        <span className="number-pattern">Apply Now</span>
              </div>
            <form className="auth-form">
              <div className="row g-4">
                <div className="col-sm-6">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input type="email" className="form-control" placeholder="Enter Your Full Name" name="name" id="name" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="email" className="form-label">
                    Email *
                  </label>
                  <input type="email" className="form-control" placeholder="Enter Your Email" name="email" id="email" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="contactNumber" className="form-label">
                      Contact number
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Contact Number"
                      className="form-control"
                      name="contactNumber"
                      id="contactNumber"
                    />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="jobRole" className="form-label">
                      Job Role *
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Job Role"
                      className="form-control"
                      name="jobRole"
                      id="jobRole"
                    />
                </div>
                <div className="col-12">
                  <label htmlFor="message" className="form-label">
                    Additional Message *
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Why do you think that you are the appropriate candidate for the opted Job role?*"
                    name="message"
                    id="message"
                    rows={3}
                    defaultValue={""}
                  />
                </div>
                <div className="col-12">
                    <label htmlFor="upload_file" className="form-label">
                      Resume
                    </label>
                    <input type="file" className="form-control" name="upload_file" id="upload_file" />
                  </div>
                <div className="col-12">
                  <a href="#" data-cursor="pointer" className="btn-solid">
                    Submit Application
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-1 col-lg-1"></div>
      </div>
    </div>
  </section>
    </>

  );
};

export default Career;
