import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const TechnologyService = () => {

  return (
    <>
     <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Technical Services
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    Your Projects; Built in Our Care
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section id="features" className="core-feature section-b-space">
  <div className="container">
    <div
      className="title2 text-center aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration={1000}
      data-aos-delay={100}
    >
      <h2>Overview</h2>
      <h3>We help businesses to find out proper Technical Solutions which can satisfy their specific

requirements in the field of Networking and Automation as well as Cyber Security. We help our customers with Professional Services for their Infrastructure Design and Implementation. 



With the help of our Vendor relations we help our customers to go for a PoC/ PoV even before finalizing a solution and going ahead with any infrastructure investment. 



Under Managed Services we take care of the NOC (Network Operations Center)/ SOC (Security Operations Center) of Enterprises.</h3>

      </div>
      <div className="row g-5 mt-5">
        <div className="col-lg-6">
          <div className="blog-main-content">
            <div>
              <div className="title">
                <h2>Services</h2>
                <h3>
                Contact Us for Schedule and Pricing
                </h3>
              </div>
              <p>
              We have customized training plan as per your need. We provide complete hands on scenario based training on Design, Deploy and Operations & Maintenance as per your environment. We have customized training plan as per your need. We provide complete hands on scenario based training on Design, Deploy and Operations & Maintenance as per your environment.
              </p>
              <ol className="pb-4">
                  <li>Site Surveys</li>
                  <li>Proof of Value/ Concept </li>
                  <li>System Integration (BOM Proposal)</li>
                  <li>Implementation Services</li>
                  <li>Project Management</li>
                  <li>Vulnerability Assessment</li>
                  <li>Penetration Testing</li>
                  <li>Infrastructure Audit</li>
                  <li>Managed Services (NOC/ SOC)</li>
                  <li>Skilled Resource Outsourcing (SRO)</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/images/cert.png`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
  </div>
</section>
<section className="about-section right-version section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-right"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/images/vendor.webp`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">Vendor Expertise</span>
                <h2>TLS - Upskill and Job Initiative</h2>
              </div>
              <p>
              Nice e-meeting you! We are here to guide you identify your
              </p>
              <ul className="home-effect">
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Cisco
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Juniper
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Aruba
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Checkpoint
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                PaloAlto
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Fortinet
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                VMware
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Google
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Microsoft
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Brocade
              </li>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />
                Array
              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<section className="about-section section-b-space section-mb-space">
    <div
      className="bg-effect"
      data-aos="fade-left"
      data-aos-duration={1000}
      data-aos-delay={500}
    />
    <div className="container">
      <div className="row g-md-5 g-4">
        <div className="col-lg-6 order-lg-0 order-1">
          <div className="about-content">
            <div>
              <div className="title">
                <span className="number-pattern">Solutions</span>
                <h2 className="text-white">
                Product and Solutions Expertise
                </h2>
              </div>
              <p>We have got the expertise to develop rich videos demonstrating the specific feature sets of the Products.</p>
              <ul>
              <li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Routers and Switches</li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Wired/ Wireless Controllers </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Load Balancers </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Next-Generation Firewalls and IPS </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Email/ Web Proxies </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Monitoring and Analytics Tools </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Internet of Things </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Software Defined Networking </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Infrastructure Automation </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />Compute and Storage </li>
<li>
                <img
                    src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
                    className="img-fluid"
                    alt="tick"
                  />VA/ PT Tools </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/character/3.svg`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  </>

  );
};

export default TechnologyService;
