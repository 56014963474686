import React, { useState, useEffect } from "react";
import { configData } from "../../config";


const Technology = () => {

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Under Development
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    Lorem ipsum content{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-section pb-md-5 pb-0">
        <div className="container">
          <h4>Under Development</h4>
        </div>
      </section>
    </>
  );
};

export default Technology;
