import { React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { Redirect } from 'react-router';
import './main.scss';
import Header from './components/Header';
import Appfooter from './components/Appfooter';
import Home from './pages/Home/Home';
import About from './pages/Allpage/About';
import Career from './pages/Allpage/Career';
import News from './pages/Allpage/News';
import Faq from './pages/Allpage/Faq';
import Blog from './pages/Allpage/Blog';
import Contact from './pages/Allpage/Contact';
import TechnologyService from './pages/Allpage/TechnologyService';
import TecnicalDomains from './pages/Allpage/TecnicalDomains';
import Portfolio from './pages/Allpage/Portfolio';
import Services from "./pages/Allpage/Services";
import ReportBug from './pages/Allpage/ReportBug';
import PrivacyPolicy from './pages/Allpage/PrivacyPolicy';
import Terms from './pages/Allpage/Terms';
import Training from "./pages/Allpage/Training";
import ContentDevelopment from "./pages/Allpage/ContentDevelopment";
import RemoteLabs from "./pages/Allpage/RemoteLabs";
import JobOriented from "./pages/Allpage/JobOriented";
import VendorRelations from "./pages/Allpage/VendorRelations";
import DesignDeliveryModels from "./pages/Allpage/DesignDeliveryModels";
import DesignDevelopment from "./pages/Allpage/DesignDevelopment";
import Technology from "./pages/Allpage/Technology";
import Industry from "./pages/Allpage/Industry";
import Products from "./pages/Allpage/Products";

import My404Component from './pages/Extra-Components/My404Component';

const App = () => {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path='/404' element={<My404Component />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />

                    <Route exact path="/portfolio" element={<Portfolio />} />
                    
                    <Route exact path="/learning/training" element={<Training />} />
                    <Route exact path="/learning/content-development" element={<ContentDevelopment />} />
                    <Route exact path="/learning/remote-labs" element={<RemoteLabs />} />
                    <Route exact path="/learning/job-oriented" element={<JobOriented />} />

                    <Route exact path="/technology/technical-services" element={<TechnologyService />} />
                    <Route exact path="/technology/vendor-relations" element={<VendorRelations />} />
                    <Route exact path="/technology/technical-domains" element={<TecnicalDomains />} />
                    <Route exact path="/technology/design-and-delivery-models" element={<DesignDeliveryModels />} />
                  
                    <Route exact path="/software/design-and-development" element={<DesignDevelopment />} />
                    <Route exact path="/software/technologies" element={<Technology />} />
                    <Route exact path="/software/industries" element={<Industry />} />
                    <Route exact path="/software/products" element={<Products />} />

                    <Route exact path="/career" element={<Career />} />
                    <Route exact path="/about-us" element={<About />} />
                    <Route exact path="/news-and-events" element={<News />} />
                    <Route exact path="/faq" element={<Faq />} />
                    <Route exact path="/blogs" element={<Blog />} />
                    <Route exact path="/contact-us" element={<Contact />} />

                    <Route exact path="/report-bug" element={<ReportBug />} />
                    <Route exact path="/sitemap" element={<Services />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route exact path="/term-and-condition" element={<Terms />} />



                    {/* <Route exact path="/organization-list" element={<PrivateRoute><OrgList /></PrivateRoute>} />
                    <Route exact path={`${process.env.PUBLIC_URL}/:slug/org-details`} element={<PrivateRoute><OrgDetails /></PrivateRoute>} /> */}

                    
                    </Routes>
                <Appfooter />
                
            </div>
        </Router>
    );
};

export default App;