import React, { useState, useEffect } from "react";
import { configData } from "../../config";

const Blog = () => {

  return (
    <>
  {/* breadcrumb section start */}
  <section className="breadcrumb-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="breadcrumb-content">
            <div>
              <h2>
                <img
                  src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                  className="img-fluid"
                  alt="title-effect"
                />
                Latest Blog
              </h2>
              <p>
                <i className="ri-subtract-line" />
                Discover the most recent blogs about artificial intelligence
                here.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-inline-block d-none">
          <div className="breadcrumb-img">
            <img
              src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
              className="img-fluid"
              alt="service"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* breadcrumb section end */}
  {/* blog section start */}
  <section className="ratio2_3">
    <div className="container">
      <div className="row g-lg-5 g-4">
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/1.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                ChatGPT: Revolutionizing Conversational AI with GPT-3.5
              </a>
              <ul>
                <li>14 march 2023</li>
                <li>5 min to read</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/2.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                {" "}
                Empowering Conversations with Advanced AI Capabilities
              </a>
              <ul>
                <li>14 march 2023</li>
                <li>5 min to read</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/3.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                {" "}
                Enabling Intelligent Conversations with Cutting-Edge AI
                Technology{" "}
              </a>
              <ul>
                <li>20 march 2023</li>
                <li>4 min to read</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/4.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                Unleashing the Potential of Conversational AI
              </a>
              <ul>
                <li>21 march 2023</li>
                <li>2 min to read</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/5.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                Unlocking the Power of Conversational AI for Enhanced User
                Experiences{" "}
              </a>
              <ul>
                <li>26 march 2023</li>
                <li>10 min to read</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="blog-box">
            <div className="blog-img">
              <a href="/blogs">
                <img
                  src={`${configData.ASSETS_URL}/assets/images/blog/6.jpg`}
                  className="img-fluid"
                  alt="blog"
                />
              </a>
              <label>Design</label>
              <div className="hover-content">
                <a data-cursor="pointer" href="/blogs">
                  <i className="iconsax" data-icon="arrow-right" />
                </a>
                <h5>- Smith warner</h5>
              </div>
            </div>
            <div className="blog-content">
              <a data-cursor="pointer" href="/blogs">
                {" "}
                Unlocking the Potential of Intelligent Conversations
              </a>
              <ul>
                <li>27 march 2023</li>
                <li>12 min to read</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="Page navigation" className="theme-pagination">
        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">
                <i className="iconsax" data-icon="chevron-left" />
              </span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              1
            </a>
          </li>
          <li className="page-item active">
            <a className="page-link" href="#">
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">
                {" "}
                <i className="iconsax" data-icon="chevron-right" />
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
  {/* blog section end */}
  {/* contact us start */}
  <section className="info-section section-b-space">
    <div className="container">
      <div
        className="info-box"
        data-aos="fade-in"
        data-aos-duration={1000}
        data-aos-delay={100}
      >
        <div className="hand-effect d-md-block d-none">
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid left-hand"
            alt="hand"
          />
          <img
            src={`${configData.ASSETS_URL}/assets/svg/hand.svg`}
            className="img-fluid right-hand"
            alt="hand"
          />
        </div>
        <h2>
          Ready to{" "}
          <span>
            move{" "}
            <img
              src={`${configData.ASSETS_URL}/assets/svg/title-effect.svg`}
              className="img-fluid"
              alt="title-effect"
            />
          </span>
          ahead?
        </h2>
        <p>
          With the help of our ground-breaking AI tool, unlock the potential of
          cutting-edge AI technology and increase your productivity to new
          heights. Embrace the future today and let our AI tool redefine what's
          possible for you.
        </p>
        <ul>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Free images for lifetime
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Get details on any topic
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            Quick advisor to help you
          </li>
          <li>
            <img
              src={`${configData.ASSETS_URL}/assets/svg/tick.svg`}
              className="img-fluid"
              alt="tick"
            />
            15+ category to explore
          </li>
        </ul>
        <a data-cursor="pointer" className="btn-arrow" href="#">
          <div className="icon-arrow">
            <i className="iconsax" data-icon="arrow-up" />
          </div>
          Contact us now
        </a>
      </div>
    </div>
  </section>
  {/* contact us end */}
</>

  );
};

export default Blog;
