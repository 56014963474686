import React, { useState, useEffect } from "react";
import { configData } from "../../config";


const Training = () => {

  return (
    <>
    <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2>
                    <img
                      src={`${configData.ASSETS_URL}/assets/images/breadcrumb-title.png`}
                      className="img-fluid"
                      alt="title-effect"
                    />
                    Technical Training
                  </h2>
                  <p>
                    <i className="ri-subtract-line" />
                    You Demand; We Deliver
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img
                  src={`${configData.ASSETS_URL}/assets/svg/contact-vector.svg`}
                  className="img-fluid"
                  alt="service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section id="features" className="core-feature section-b-space">
  <div className="container">
    <div
      className="title2 text-center aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration={1000}
      data-aos-delay={100}
    >
      <h2>Vendor Specific Certifications Career Paths</h2>
      <h3>We provide Associate to Expert level training for different Technology Solutions from different Vendors. These training's cover the hands on usage of the solutions and are aligned with Real World Scenarios.</h3>

      </div>
      <div className="row g-5 mt-5">
        <div className="col-lg-6">
          <div className="service-info">
            <div>
              <div className="title">
                <h2>Cisco Certification Career Paths</h2>
                <h3>
                Contact Us for Schedule and Pricing
                </h3>
              </div>
              <p>
              We have customized training plan as per your need. We provide complete hands on scenario based training on Design, Deploy and Operations & Maintenance as per your environment. We have customized training plan as per your need. We provide complete hands on scenario based training on Design, Deploy and Operations & Maintenance as per your environment.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img">
            <img
              src={`${configData.ASSETS_URL}/assets/images/cert.png`}
              className="img-fluid"
              alt="about"
            />
          </div>
        </div>
      </div>
  </div>
</section>
<section className="ratio2_1">
  <div className="container">
    <div className="row g-5">
      <div className="col-12">
        <div className="blog-listing">
          <div className="blog-img">
            <img
              src={`${configData.ASSETS_URL}/assets/images/blog/1.jpg`}
              className="img-fluid bg-img"
              alt="blog"
            />
            <label>Design</label>
          </div>
          <div className="blog-content">
            <a
              data-cursor="pointer"
              className="main-title"
              href="blog-details.html"
            >
              Customized
            </a>
            <p>
            Do you want to train your Infrastructure team? We have customized training plan as per your need. We provide complete hands on scenario based training on Design, Deploy and Operations & Maintenance as per your environment.
            </p>
            <a
              data-cursor="pointer"
              className="btn-arrow"
              href="/"
            >
              <div className="icon-arrow">
                <i className="iconsax" data-icon="arrow-up" />
              </div>
              Request a Callback
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>

  );
};

export default Training;
